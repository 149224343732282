/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;900&display=swap");
/* @import "assets/scss/colors"; */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #FF8111 !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #FF8111 !important;
}

.mat-icon.icon-color-accent svg g {
  fill: #FF8111;
}
.mat-icon.icon-color-accent svg g path {
  fill: #FF8111;
}
.mat-icon.icon-color-accent svg path {
  fill: #FF8111;
}
.mat-icon.icon-color-gray svg g {
  fill: #787878;
}
.mat-icon.icon-color-gray svg g path {
  fill: #787878;
}
.mat-icon.icon-color-gray svg path {
  fill: #787878;
}

.width-100 {
  width: 100%;
}

.tb-row {
  display: flex;
  flex-direction: row;
}

.tb-justify-sb {
  justify-content: space-between;
}

.buttonAceppt {
  background-color: #FF8111;
  color: white;
}

.buttonCancel {
  background-color: #676667;
  color: white;
  margin-right: 5px;
}

.buttonExit {
  background-color: #f90122;
  color: white;
  margin-right: 5px;
}

.tb-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  z-index: 99999;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: transparent;
}
.tb-spinner mat-progress-spinner {
  margin: 15% auto;
}
.tb-spinner.mat-progress-spinner circle,
.tb-spinner .mat-spinner circle {
  stroke: #FF8111;
}

mat-progress-bar .mat-progress-bar-buffer {
  background: #FF8111;
}

.mat-progress-bar-fill::after {
  background-color: #FF8111;
}

table {
  width: -webkit-calc(100% - 10px);
  width: -moz-calc(100% - 10px);
  width: calc(100% - 10px);
}
table tr.element-row:hover {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: scaleX(1.01);
  background: whitesmoke;
  cursor: pointer;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 0px;
  /* color: #ff00cb; */
}

.mat-button-toggle-checked {
  background: linear-gradient(88.94deg, #D43B1F -0.47%, #FF8111 100%);
  color: white !important;
  font-weight: 500;
  /* width: 100%; */
}
.mat-button-toggle-checked .mat-form-field-appearance-outline .mat-form-field-outline {
  border: none !important;
}

.mat-button-toggle-appearance-standard {
  color: #FF8111;
  font-weight: 500;
}

.mat-button-toggle-button {
  height: 35px !important;
  border: solid 1px #FF8111 !important;
  border-radius: 16px;
}

.tb-column {
  display: flex;
  flex-direction: column;
}

.tb-align-center {
  align-items: center;
}

.tb-justify-sb {
  justify-content: space-between;
}

.tb-row {
  display: flex;
  flex-direction: row;
}

.tb-justify-center {
  justify-content: center;
}

.tb-text-14 {
  font-size: 14px;
}

.tb-text-32 {
  font-size: 32px;
}

.tb-text-20 {
  font-size: 20px;
}

/* colors */
.tb-grey {
  color: #787878;
}

.tb-redMcbrokers {
  color: #FF8111;
}

.tb-blue {
  color: #2d008a;
}

.color-primary {
  color: #FF8111;
}

.color-secondary {
  color: #93979B;
}

.color-tertiary {
  color: #D43B1F;
}

.tb-white {
  color: white;
}

.color-black {
  color: black;
}

/* font-weigth */
.font-weigth-500 {
  font-weight: 500;
}

.font-weigth-700 {
  font-weight: 700;
}

.tb-text-bold {
  font-weight: bold !important;
}

.tb-baseline {
  align-items: baseline;
}

.tb-text-center {
  text-align: center !important;
}

/* spacin */
.mr-20 {
  margin-right: 20px;
}

.mr-21 {
  margin-right: 21px;
}

.spacer {
  flex: 1 1 auto;
}

.input-440-32 .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.9em !important;
}
.input-440-32 input.mat-input-element {
  margin-bottom: 4px;
}
.input-440-32 .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0.1em !important;
}
.input-440-32 .mat-form-field .mat-form-field-flex {
  height: 40px !important;
}
.input-440-32 .mat-form-field-infix {
  border-top: none !important;
  width: 100%;
  /* width: 280px; */
}
.input-440-32 .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  border-radius: 30px !important;
  border: transparent !important;
  border: 2px solid #FF8111 !important;
}
.input-440-32 .mat-form-field-appearance-outline .mat-form-field-outline {
  color: transparent !important;
}
.input-440-32 .mat-form-field-label {
  color: #FF8111;
  font-size: 14px;
  letter-spacing: -0.28px;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #FF8111;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #0061AA;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #FF8111;
}

/* SNACKBAR */
.nm-blue-snackbar {
  background-color: #3A8CE9;
  color: white;
  font-size: 14px;
  font-family: "Roboto";
  text-align: left;
}

.nm-red-snackbar {
  background-color: #fc3737;
  color: white;
  font-size: 14px;
  font-family: "Roboto";
  text-align: left;
}

/* END SNACKBAR */
ul {
  list-style: none;
}