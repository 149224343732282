$color-alert: #fc3737;
$color-blue-text: #0b0656;
$color-primary-gradient: linear-gradient(88.94deg, #D43B1F -0.47%, #FF8111 100%);
$color-card-membership: linear-gradient(88.94deg, #D43B1F -0.47%, #FF8111 100%);

$color-primary: #FF8111;
$color-secondary: #93979B;
$color-tertiary: #D43B1F;
$button-disabled: #bcbcbc;
$button-success: #5fd997;
$button-warning: #ffcf61;
$button-danger: #ff475d;